import React from 'react';
import styled from 'styled-components';
import Quotes from './components/quotes/quotes';
import './App.css';
import Motivation from './components/motivation/motivation';

const Header = styled.div`
  width: 80vw;
  height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Sacramento", cursive;
  text-align: center;
  font-size: calc(3.25rem + 3.25vmin);
  margin: 2vh auto;
  color: #555555;
`;

const SpotifyContainer = styled.iframe`
  border: none;
  border-radius: 0.75rem;
  width: 80vw;
  margin: 4vh auto;
`;

function App() {
  return (
    <div className="App">
      <Header>
        Jasmin
      </Header>
      <Motivation />
      <Quotes />
      <SpotifyContainer src="https://open.spotify.com/embed/playlist/6mhbyR84iCcgJSKbMqaCT6?utm_source=generator&theme=0" height="352" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></SpotifyContainer>
    </div>
  );
}

export default App;
