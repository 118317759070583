import React from "react";
import styled from "styled-components";

const MotivationContainer = styled.div`
    font-size: calc(1.25rem + 1.25vmin);
    font-family: "Salsa", cursive;
    font-weight: 400;
    font-style: normal;
    text-align: center;
    margin: 0 auto;
    width: 80vw;
`;

const Motivation = () => {
    return (
        <MotivationContainer>
            Hello Jasmin, Remember that you are surrounded always by people whom care about you. Right now you are feeling probably pretty low with everything that has gone on, but do not worry as we all have your back and will help you succeed in whatever your heart desires. You have a strong support system behind you and we all will help you get through anything life throws at you. Just remember to always smile, love life, take time for yourself, and don't over work yourself.
        </MotivationContainer>
    )
}

export default Motivation;