import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

interface Quote {
  text: string;
  author: string;
}

const QuoteContainer = styled.div`
    font-size: calc(1.5rem + 1.5vmin);
    font-family: "Salsa", cursive;
    font-weight: 400;
    font-style: normal;
    width: 80vw;
    height: 100vh;
    margin: 0 auto;
`;

const motivationalQuotes: Quote[] = [
    { text: "Just one small positive thought in the morning can change your whole day", author: "Dalai Lama" },
    { text: "Opportunities don't happen, you create them", author: "Chris Grosser" },
    { text: "Believe you can and you're halfway there", author: "Theodore Roosevelt" },
    { text: "There will always be hurdles in life, but if you want to achieve a goal, you must continue", author: "Malala Yousafzai" },
    { text: "Remember to look up at the stars and not down at your feet. Try to make sense of what you see and wonder about what makes the universe exist. Be curious. And however difficult life may seem, there is always something you can do and succeed at. It matters that you don't just give up", author: "Stephen Hawking" },
    { text: "Doubt kills more dreams than failure ever will", author: "Suzy Kassem" },
    { text: "If you're going through hell, keep going.", author: "Winston Churchill" },
    { text: "Our greatest weakness lies in giving up. The most certain way to succeed is always to try just one more time.", author: "Thomas A. Edison" },
    { text: "The happiness of your life depends on the quality of your thoughts", author: "Marcus Aurelius" },
];

const Quotes: React.FC = () => {
  const [displayedQuotes, setDisplayedQuotes] = useState<Set<string>>(new Set());
  const [quote, setQuote] = useState<Quote | null>(null);

  useEffect(() => {
    const pickRandomQuote = () => {
      const availableQuotes = motivationalQuotes.filter(
        (q) => !displayedQuotes.has(q.text)
      );

      // If all quotes are displayed, reset displayedQuotes
      if (availableQuotes.length === 0) {
        setDisplayedQuotes(new Set());
      }

      if (availableQuotes.length > 0) {
        const randomIndex = Math.floor(Math.random() * availableQuotes.length);
        const newQuote = availableQuotes[randomIndex];
        setQuote(newQuote);
        setDisplayedQuotes((prev) => new Set(prev).add(newQuote.text));
      }
    };

    const intervalId = setInterval(pickRandomQuote, 10000);

    // Cleanup function to clear the interval on unmount
    return () => clearInterval(intervalId);
  }, [displayedQuotes]);

  return (
    <QuoteContainer>
      {quote ? (
        <>
          <p>{quote.text}</p>
          <span>- {quote.author}</span>
        </>
      ) : (
        <p>Loading...</p>
      )}
    </QuoteContainer>
  );
};

export default Quotes;
